import "./Especialidades.css"
import { Link } from 'react-router-dom'
import BtnTransparent from "../../BtnTransparent/BtnTransparent"
import IMG1 from '../../../assets/IMG1.webp';
import IMG2 from '../../../assets/IMG2.webp';
import IMG3 from '../../../assets/IMG3.webp';

function Especialidades() {
    return (
        <section className="sectionEspecialidades">
            <div className="divEspecialidades">
                <div className="especialidadesItem">
                    <img src={IMG1} alt="Psicopatologia" loading="lazy"/>
                    <p>Psicopatologia</p>
                    <Link to="/especialidades"><BtnTransparent text="saiba mais" aria-label="Saiba mais" /></Link>
                </div>
                <div className="especialidadesItem">
                    <img src={IMG2} alt="Prática baseada em evidência" loading="lazy"/>
                    <p>Prática Baseada em Evidência</p>
                    <Link to="/especialidades"><BtnTransparent text="saiba mais" aria-label="Saiba mais" /></Link>
                </div>
                <div className="especialidadesItem">
                    <img src={IMG3} alt="TCC" loading="lazy" />
                    <p>Terapia Cognitivo Comportamental</p>
                    <Link to="/especialidades"><BtnTransparent text="saiba mais" aria-label="Saiba mais"/></Link>
                </div>
            </div>
        </section>
    )
}

export default Especialidades