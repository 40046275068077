import './Btn.css'
import React from 'react';

function Btn({ text, href }) {
    return (
        <a href={'https://wa.me/5521964985917'} className="btn">
            {text}
        </a>
    );
}

export default Btn;