import Home from './Pages/Home'
import Sobre from './Pages/Sobre'
import Contato from './Pages/Contato'
import Consultas from './Pages/Consultas'
import Especialidades from './Pages/Especialidades'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

function App() {
  return (
    
    <div className="App">
    
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/sobre">
            <Sobre />
          </Route>

          <Route exact path="/contato">
            <Contato />
          </Route>

          <Route exact path="/consultas">
            <Consultas />
          </Route>

          <Route exact path="/especialidades">
            <Especialidades />
          </Route>

        </Switch>
      </Router>
    </div>
  )

}
export default App
