import "./ContatoTxt.css"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"

function ContatoTxt() {
    return (

        <section className="formContainer">
            <h3>Entre em contato</h3>
            <div className="contatoWrapper">

                <div className="contatoEsq">
                    <p>
                        Aqui estão todas as formas de contato comigo para que você possa tirar dúvidas relacionadas ao 
                        meu atendimento.
                    </p>
                </div>


                <div className="linhaContato" />


                <div className="contatoDir">
                    <p>  <FaWhatsapp /> <a href="https://wa.me/5521964985917"> Fale comigo no whatsapp</a> </p>

                    <br />
                    <p>  <FaInstagram /> <a href="https://instagram.com/paulo.mourafilho"> @paulo.mourafilho </a> </p>
                </div>
            </div>
        </section>
    )
}

export default ContatoTxt