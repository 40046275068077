import Btn from "../Btn/Btn"
import "./SobreMim.css"
import sobremimfoto from "../../assets/sobremimfoto.webp"
import graduado from "../../assets/graduado.webp"
import estudar from "../../assets/estudar.webp"
import livros from "../../assets/livros.webp"
import psicologia from "../../assets/psicologia.webp"
import terapia from "../../assets/terapia.webp"


function SobreMim() {
    return (
        <div className="sobreMimWrapper">
        <div className="cabecalho">

            <img className="paulo" src={sobremimfoto} alt="Paulo Moura" loading="lazy"/>
        </div>

        <div className="listaSobre">

            <div className="tituloSobre">
                <h3>Sobre mim & Currículo</h3>
            </div>
            <div className="itemUm">
            <img className="imgUm" src={graduado} alt="Graduação" loading="lazy"/>
                <p>Eu sou Paulo Moura. Durante meu percurso acadêmico, tive a oportunidade de ser monitor das disciplinas 
                de psicopatologia e psicodiagnóstico. Esse período me permitiu aprimorar minhas habilidades de avaliação
                 e diagnóstico psicológico, o que considero fundamental para oferecer um atendimento de qualidade aos meus
                 pacientes.</p>
            </div>

            <div className="itemDois">
            <img className="imgDois" src={estudar} alt="Estudos" loading="lazy" />
                <p>Além disso, fiz estágio acadêmico no núcleo de avaliação neuropsicológica e terapia cognitivo comportamental,
                 o que me proporcionou uma base sólida para a compreensão dos processos cognitivos e neurológicos envolvidos 
                 nas diversas manifestações do comportamento humano. </p>
            </div>
            
            <div className="itemTres">
            <img className="imgTres" src={livros} alt="Livros" loading="lazy" />
            <p>Acredito que a ciência baseada em evidências é uma ferramenta fundamental para fornecer tratamentos eficazes 
            e embasados em metodologia científica de alto padrão para os indivíduos que buscam ajuda psicológica. Por isso, 
            também faço especialização em Neurociência e Psicopatologia na PUC.</p>             
            </div>

            <div className="itemQuatro">
            <img className="imgQuatro" src={psicologia} alt="Psicologia" loading="lazy"/>
            <p>Ao longo de minha jornada acadêmica e prática, tenho adquirido conhecimento teórico e experiência prática, o que 
            me permite abordar cada caso com empatia, respeito e ética.</p>
            </div>

            <div className="itemCinco">
            <img className="imgCinco" src={terapia} alt="Crescimento" loading="lazy"/>
            <p>Busco compreender as particularidades de cada pessoa e oferecer um ambiente acolhedor e seguro para que possam 
                explorar suas questões e alcançar o bem-estar emocional e uma vida que vale a pena ser vivida. Para mim, é uma 
                grande satisfação poder ajudar as pessoas a superar desafios e encontrar sentido em suas vidas.</p>
            </div>
        </div>
        
        <Btn text="vamos conversar?" aria-label="Whatsapp"/>
             </div>
    )
    }
    export default SobreMim