import "./TodasEspecialidades.css"
import IMG1 from "../../assets/IMG1.webp"
import IMG2 from "../../assets/IMG2.webp"
import IMG3 from "../../assets/IMG3.webp"

function TodasEspecialidades() {
    return (
        <div className="TodasEspecialidadesWrapper">
            <div className="especialidadeUm">
                <img className="especialidadeImg" src={IMG1} alt="Especialidades" loading="lazy" />
                <h3>Psicopatologia</h3>
                <p>Campbell (1986) define a psicopatologia como o ramo da ciência que trata da natureza
                    essencial da doença ou transtorno mental - suas causas, as mudanças estruturais e funcionais
                    associadas a ela e suas formas de manifestação. Entretanto, nem todo estudo psicopatológico
                    segue a rigor os ditames de uma "ciência dura", "ciência sensu strictu". A psicopatologia, em
                    acepção mais ampla, pode ser definida como o conjunto de conhecimentos referentes ao
                    adoecimento mental do ser humano. E um conhecimento que se esforça por ser sistemático,
                    elucidativo e desmistificante. Como conhecimento que visa ser cientifico, a psicopatologia não
                    inclui critérios de valor, nem aceita dogmas ou verdades a priori. Ao se estudar e praticar a
                    psicopatologia, não se julga moralmente aquilo que se estuda; busca-se apenas observar,
                    idenficar e compreender os diversos elementos do transtorno mental. Além disso, em
                    psicopatologia, deve-se rejeitar qualquer tipo de dogma, qualquer verdade pronta e intocável,
                    seja ela religiosa, seja ela filosófica, psicológica ou biológica; o conhecimento que se busca está
                    permanentemente sujeito a revisões, críticas e reformulações. Ou seja, a psicopatologia como
                    ciência dos transtornos mentais requer um debate científico e público constante de todos os
                    seus postulados, noções e verdades encontradas.
                    <br />
                    <strong>Referências: </strong>
                    CAMPBELL, 1986 apud DALGALARRONDO, 2018.
                    DALGALARRONDO, Paulo. Psicopatologia e semiologia dos transtornos mentais. Artmed
                    Editora, 2018.</p>
            </div>

            <div className="especialidadeDois">
                <img className="especialidadeImg" src={IMG2} alt="Especialidades" loading="lazy"/>
                <h3>Prática Baseada em Evidência</h3>
                <p>
                Segundo Gabbard (2006), a prática da Psicologia Baseada em Evidências pressupõe a
integração da avaliação criteriosa dos resultados das pesquisas de alta qualidade metodológica
às características individuais dos pacientes, bem como a suas preferências, exigindo do
profissional de saúde mental a escolha da alternativa terapêutica mais da.
Decisões clínicas baseadas em evidências têm como prerrogativa o uso consciente, distinto e
criterioso das melhores evidências sobre o cuidado individual do paciente. Dentro dessa
perspectiva, busca-se a integração entre a habilidade clínica individual e a melhor evidência
científica criteriosa disponível nas pesquisas sistemáticas de literatura. Trata-se da psicoterapia
baseada em evidências objetivas, “o esforço em identificar, testar, desenvolver e estimular a
disseminação de técnicas validadas em pesquisas científicas” (Pheula, Isolan & Rassier, 2007,
pg. 81). <br/>
<strong>Referências: </strong> Gabbard, O. G. (2006). Psiquiatria Psicodinâmica na Prática Clínica. Porto Alegre,
RS: Artmed.
Pheula, Gabriel Ferreira & Isolan, Luciano Rassier. (2007). Psicoterapia baseada em evidências
em crianças e adolescentes. Archives of Clinical Psychiatry (São Paulo), 34(2), 74-83.

                </p>
            </div>

            <div className="especialidadeTres">
                <img className="especialidadeImg" src={IMG3} alt="Especialidades" loading="lazy" />
                <h3>Terapia Cognitivo Comportamental</h3>
                <p>
                A TCC é definida por Wright, Basco e Thase (2009) como uma abordagem que se baseia em
dois princípios centrais: 1) as cognições possuem influência controladora sobre as emoções e
os comportamentos e 2) o modo de comportar-se de um indivíduo pode afetar seus padrões
de pensamento e suas emoções. O objetivo dessa abordagem psicoterápica está centrado na
modificação de pensamentos, significados, em uma mudança emocional e duradoura, bem
como na autonomia do paciente e no alívio e remissão dos sintomas que ele apresenta (Beck,
1993). Em suma, o foco dessa abordagem é a identificação e correção de padrões de
pensamento conscientes ou não conscientes (Knapp, 2009) O modelo cognitivo parte da teoria
de que pensamentos disfuncionais e não realistas influenciam diretamente o humor e o
comportamento do indivíduo, ou seja, a forma como uma situação é compreendida e avaliada
influencia a intensidade dos sentimentos (Lotufo Neto & Baltieri, 2001). Para a interpretação
do fenômeno psicológico, o trabalho tem seu foco nos fatores cognitivos, ou seja, os
significados atribuídos pelos indivíduos a acontecimentos e demais aspectos da sua vida estão,
para essa abordagem, inter-relacionados aos comportamentos: com essas interpretações, o
indivíduo formula hipóteses sobre si mesmo, o mundo e seu futuro (Knapp, 2009). As emoções
e os comportamentos não são gerados pelos eventos em si, mas sim pelo que se pensa sobre
os eventos, ou seja, emoções e comportamentos são influenciados pelo pensamento. Análises
deficitárias sobre o que realmente está acontecendo geram sentimentos inadequados para que
a pessoa lide com a situação (Lotufo Neto & Baltieri, 2001). A inter relação entre cognição,
emoção e comportamento está envolvida no funcionamento humano, sobretudo nas
psicopatologias (Knapp, 2009).
<br />
                    <strong>Referências: </strong> Wright, J. H., Basco, M. R., & Thase, M. E. (2009). Aprendendo a terapia cognitivo
comportamental. Porto Alegre, RS: Artmed.
Beck A.T. (1993). Cognitive Therapy: Past, Present and Future. Journal of Consulting and clinical
Psychology, 61(2),194-198.
Knapp, P. (2009). Terapia cognitivo-comportamental na prática psiquiátrica. Porto Alegre, RS:
Artmed.
Lotufo Neto, Francisco & Baltieri, Maria Angelita. (2001). Processos cognitivos e seu tratamento
no transtorno obsessivo-compulsivo. Revista Brasileira de Psiquiatria, 23(Supl. 2), 46-48.
                </p>
            </div>

        </div>
    )
}

export default TodasEspecialidades