import Navbar from "../Components/Navbar/Navbar";
import Banner from "../Components/Banner/Banner";
import Footer from "../Components/Footer/Footer";
import Especialidades from "../Components/ResumosHome/Especialidades/Especialidades";
import Sobre from "../Components/ResumosHome/Sobre/Sobre";
import Consultorio from "../Components/ResumosHome/Consultório/Consultorio";

function Home() {
    return (
        <div>
            <Navbar />
            <Banner />
            <Especialidades />
            <Sobre />
            <Consultorio />
            <Footer />
        </div>
    )
}

export default Home