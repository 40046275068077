import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import TodasEspecialidades from "../Components/TodasEspecialidades/TodasEspecialidades";

function Especialidades() {
    return (
        <div>
            <Navbar />
            <TodasEspecialidades />
            <Footer />
        </div>
    )
}

export default Especialidades