import "./Sobre.css"
import { Link } from 'react-router-dom'
import fotoPaulo from '../../../assets/fotoPaulo.webp';
import cerebro from '../../../assets/cerebro.webp'



function Sobre() {
    return (
        <section className="sobreContainer">
            <div className="sobreWrapper">
                <div className="sobreFoto">
                    <img src={fotoPaulo} alt="Paulo Moura" loading="lazy" />
                </div>

                <div className="sobreConteudo">
                    <img className="iconeSobre" src={cerebro} alt="cérebro" loading="lazy" />
                    <p>
                    Psicólogo Clínico, atua com Terapia Cognitivo Comportamental. Tem curso de formação em Psicologia 
                    Baseada em evidências pelo InPBE-SP. Durante seu percurso acadêmico atuou no departamento de
                     Neuropsicologia e Terapia Cognitivo Comportamental o que lhe permite ter habilidades mais sólidas 
                    para compreensão das psicopatologias, dos processos cognitivos e neurológicos envolvidos nas diversas 
                    manifestações do comportamento humano. Atualmente faz pós-graduação em Neurociência e Psicopatologia na PUC. 
                    </p>
                    <Link to="/sobre"><p>Ler mais</p></Link>
                    <div className="linhaSobre" />
                </div>
            </div>
        </section>
    )
}

export default Sobre