import "./Footer.css"


function Footer () {
    
    return (
        
        
        <div className="footer">
        <footer>
            <p>Desenvolvido por <strong><a href="https://www.instagram.com/raycadilhe">Rayssa Cadilhe</a></strong>.</p>
        </footer>
        
        </div>

        
    )
    
}

export default Footer
