import "../Banner/Banner.css"
import { Link } from 'react-router-dom'
import ButtonColor from "../BtnColor/BtnColor.jsx"

function Banner() {
    return (
        <div className="bannerFoto">
            <div className="bannerLogo">
            <h3 className="bannerNome">Paulo</h3>
            <h3 className="bannerSobrenome">Moura</h3>
            <Link to="/sobre"><ButtonColor text="conheça" /></Link>
            </div>
        </div>
    )
}

export default Banner