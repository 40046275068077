import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import Consultorio from "../Components/Consultas/Consultas"

function Consultas() {
    return (
        <div>
            <Navbar />
            <Consultorio />
            <Footer />
        </div>
    )
}

export default Consultas