import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import SobreMim from "../Components/Sobremim/SobreMim"

function Sobre() {
    return (
        <div>
            <Navbar />
            <SobreMim />
            <Footer />
        </div>
    )
}

export default Sobre