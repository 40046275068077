import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import ContatoTxt from "../Components/Contato/ContatoTxt";

function Contato() {
    return (
        <div>
            <Navbar />
            <ContatoTxt />
            <Footer />
        </div>
    )
}

export default Contato